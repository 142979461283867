/* @import url('https://fonts.googleapis.com/css?family=Press+Start+2P'); */


#unauthorized_access {
  padding: 1rem;
  background: black;
  display: flex;
  height: 100vh;
  justify-content: center; 
  align-items: center;
  color: #54FE55;
  text-shadow: 0px 0px 10px;
  font-size: 6rem;
  flex-direction: column;
}

.unauthorized_access_txt {
  font-size: 1.8rem;
}

.unauthorized_access_link {
  font-size: 1rem;
  color:#54FE55;
}

@keyframes blink {
  0% { opacity: 0; }
  49% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
}

.unauthorized_access_blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
