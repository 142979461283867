.email-container {
    max-width: 600px;
    margin: auto;
    padding: 40px;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px 0px #c5c5c5;
 
}
.email-header, .email-footer {
    text-align: center;
    padding: 10px 0;
    background-color: #007bff;
    color: #ffffff;
}
.email-content {
    padding: 20px;
}
.email-content p {
    margin: 10px 0;
    line-height: 25px;
}
.email-footer a{
    color: #fff;
    text-decoration: none;
}
.email-footer{
    cursor: pointer;
}
.h-vh{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.email-footer p{
    margin-bottom: 0px;
}
.login-details {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #dddddd;
    margin: 20px 0;
}
.login-details p {
    margin: 8px 0;
    font-weight: bold;
}
.login-details span {
    font-weight: normal;
}
a {
    color: #007bff;
    text-decoration: none;
}