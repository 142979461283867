.top-navbar .nav-link{
font-size: 13px;
font-weight: 500;
line-height: 14.52px;
font-family: "Inter", sans-serif;
}
.common-navbar .nav-link{
    font-weight: 600;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    line-height: 19.36px;
    color: #000000;
}
.carousel-wrapper {
    height: 100vh; /* Full height of the viewport */
    position: relative;
  }
  .carousel-wrapper .carousel-indicators .active{
    background-color:#005059;
    height:12px ;
    width: 34px;
  }
  .carousel-wrapper .carousel-item {
    height: 100vh; /* Full height of the viewport */
  }
  
  .carousel-wrapper .carousel-item img {
    height: 100vh; /* Full height of the viewport */
    object-fit: cover; /* Cover the entire container */
  }
  
  .carousel-caption {
    position: absolute;
    top: 40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  .carousel-indicators{
    bottom:40% !important
  }
  .carousel-caption h3 {
    font-size:  60px;
        font-weight: 900;
        line-height: 77.45px;
        font-family: "Inter", sans-serif;
        margin-bottom: 20px;

  }
  
  .carousel-caption .btn {
    background: linear-gradient(180deg, #FD5523 0%, #F97047 50%, #EB8263 100%);
 /* Change color as needed */
 box-shadow: 0px 4px 3.9px 0px #00000059;
font-size: 14px;
font-weight: 700;
font-family: "Inter", sans-serif;
line-height: 16.94px;
border-radius: 30px;
min-width: 174px;
min-height: 45px;  }
  